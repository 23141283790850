import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllRoles, getUserRoles, getUsers, setEditedUserRoleId, updateUserExpirationDate} from "../../actions";
import {Button} from "@mui/material";
import TrafficIcon from '@mui/icons-material/Traffic';
import {useNavigate} from "react-router";

function Users() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const {users, authorization} = useSelector(
        state => state.userReducers
    );

    const handleEditRoles = async (params) => {
        let usuario_id = params.row.usuario_id;
        await dispatch(getUserRoles(usuario_id, authorization.access_token)).then()
        await dispatch(getAllRoles(authorization.access_token)).then()
        await dispatch(setEditedUserRoleId(usuario_id))
        navigate("/role")
    }

    const handleEditRowsModelChange = async (model) => {
        let key = Object.keys(model)[0]
        if (key != null) {
            let exp = model[key].validade
            let expiration = new Date(exp.value).toISOString()
            if (Object.keys(editRowsModel).length > 0 && editRowsModel !== model) {
                dispatch(updateUserExpirationDate(key, expiration))
            }
        }
        setEditRowsModel(model);
    }

    const usersColumns = [
        {field: "usuario_id", headerName: "ID", width: 50},
        {field: "disabled", headerName: "Ativo", width: 100},
        {
            field: "validade", headerName: "Validade", width: 300, editable: true, type: "dateTime",
        },
        {field: "nome", headerName: "Nome", width: 300},
        {field: "email", headerName: "E-mail", flex: 1},
        {field: "inscricao", headerName: "Inscrição", width: 200},
        {field: "cpf", headerName: "CPF", width: 150},
        {field: "telefone", headerName: "Telefone", width: 150},
        {
            field: "roles", headerName: "Roles", width: 100, sortable: false,
            renderCell: (params) => {
                return (
                    <Button
                        size="small"
                        variante="outlined"
                        onClick={() => handleEditRoles(params)}
                    >
                        <TrafficIcon/>
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getUsers(authorization.access_token)).then();
    }, [dispatch, authorization]);

    return (
        <div style={{width: "100%"}}>
            <DataGrid className="users"
                      getRowId={(row) => row.usuario_id}
                      rows={users}
                      columns={usersColumns}
                      pageSize={25}
                      autoHeight={true}
                      rowHeight={25}
                      headerHeight={25}
                      editRowsModel={editRowsModel}
                      onEditRowsModelChange={handleEditRowsModelChange}
                      initialState={{
                          sorting: {
                              sortModel: [{field: "nome", sort: "asc"}],
                          },
                      }}
            />
        </div>
    )
}

export default Users;