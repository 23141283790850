import React, {useEffect} from "react";
import {DataGrid} from "@mui/x-data-grid";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {Button, Grid, IconButton} from "@mui/material";
import {DropDown} from "../../components/common/DropDown";
import {addSeason, getAddedChampionships, getSeasons, removeSeason, setSeason, updateSeason,} from "../../actions";
import {useDispatch, useSelector} from "react-redux";

function Championship() {
    const dispatch = useDispatch();
    const {addedChampionships, seasons, season} = useSelector(
        (state) => state.userReducers
    );

    const remover = async (id) => {
        await dispatch(removeSeason(id)).then();
        dispatch(getSeasons()).then();
    };

    const championshipColumns = [
        {
            field: "delete",
            headerName: "Remover",
            width: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Button
                        size="small"
                        variante="outlined"
                        onClick={() => remover(params.id)}
                    >
                        <DeleteIcon/>
                    </Button>
                );
            },
        },
        {
            field: "load",
            headerName: "Carregar",
            width: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Button
                        size="small"
                        variante="outlined"
                        onClick={() => dispatch(updateSeason(params.id))}
                    >
                        <AutorenewIcon/>
                    </Button>
                );
            },
        },
        {field: "id", headerName: "ID", width: 90},
        {field: "name", headerName: "Campeonato", flex: 1},
        {field: "year", headerName: "Ano", width: 100},
        {field: "cron", headerName: "Agendamento", width: 200},
        {field: "lastUpdated", headerName: "Ultima Atualização", width: 250}
    ];

    const handleSeasonChange = (event) => {
        dispatch(setSeason(event.target.value));
    };

    useEffect(() => {
        dispatch(getAddedChampionships()).then();
        dispatch(getSeasons()).then();
    }, [dispatch]);

    const handleAddSeason = async (event) => {
        await dispatch(addSeason(season)).then();
        dispatch(getSeasons()).then();
        dispatch(setSeason(""));
    };

    return (
        <div className="championship">
            <DataGrid
                rows={addedChampionships}
                columns={championshipColumns}
                pageSize={25}
                autoHeight={true}
                rowHeight={25}
                headerHeight={25}
                initialState={{
                    sorting: {
                        sortModel: [{field: "name", sort: "asc"}],
                    },
                }}
            />
            <Grid
                container
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="championship-dropdown"
            >
                <Grid item>
                    <DropDown
                        labelId="match-championship-selection-label"
                        id="match-championship-selection"
                        value={season}
                        onChange={handleSeasonChange}
                        label="Campeonato"
                        items={seasons}
                        variant="standard"
                        sx={{m: 1, minWidth: 120}}
                    />
                </Grid>
                <Grid item xs={1} sm={1} md={1} className="championship-add-button">
                    <IconButton onClick={handleAddSeason}>
                        <AddIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
}

export default Championship;
